"use client"

import { cn } from "@/utils/cn"
import { useEffect } from "react"
import { Icon, IconType } from "./icon"
import { useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { useFavorites } from "@/web/hooks/useFavorites"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"

interface LikeProps {
  artwork: Erc721Token
}

export const FavoriteToggler = ({ artwork }: LikeProps): JSX.Element => {
  const [showLikes, setShowLikes] = useState(false)
  const { isFavorite, likeCount, handleClickFavorite } = useFavorites(artwork)
  const [icon, setIcon] = useState<IconType>("heart")

  useEffect(() => {
    if (isFavorite) setIcon("heartFilled")
    else setIcon("heart")
  }, [isFavorite])

  useEffect(() => {
    if (showLikes) {
      const timer = setTimeout(() => {
        setShowLikes(false)
      }, 1_000)
      return () => clearTimeout(timer)
    }
  }, [likeCount])

  return (
    <div className="relative flex">
      <AnimatePresence>
        {showLikes && likeCount > 0 && (
          <motion.span
            animate={{
              opacity: [0, 1],
              x: [10, 0],
              transition: { duration: 0.2, ease: "easeOut" },
            }}
            exit={{
              opacity: 0,
              x: -20,
              transition: { duration: 0.2, ease: "easeIn" },
            }}
            className={`
              absolute right-6 -mt-1 flex gap-x-1 whitespace-nowrap rounded-md border border-sr-border-primary
              bg-sr-bg-primary px-3 py-0.5 text-sm text-sr-text-primary
            `}
          >
            <motion.span
              key={likeCount}
              initial={{ opacity: 1, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              {likeCount}
            </motion.span>
            likes
          </motion.span>
        )}
      </AnimatePresence>
      <motion.button
        whileTap={{ scale: 1.3 }}
        className={cn("flex items-center gap-x-2")}
        onClick={() => {
          handleClickFavorite()
          setShowLikes(true)
        }}
      >
        <Icon className="dark:invert" name={icon} />
      </motion.button>
    </div>
  )
}
