import { useToggle } from "react-use"
import { trpc } from "@/web/clients/trpc"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import toast from "react-hot-toast"
import { useProfileStore } from "@/web/stores/profile"
import { useAccount } from "wagmi"
import { useConnectModal } from "@rainbow-me/rainbowkit"

export const useMasthead = (artwork: Erc721Token, onComplete?: () => void) => {
  const { isConnected } = useAccount()
  const { openConnectModal: showDialog } = useConnectModal()
  const [isLoading, setIsLoading] = useToggle(false)
  const [isMutating, setIsMutating] = useToggle(false)
  const {
    profile: account,
    mastheadArtwork,
    setMastheadArtwork,
  } = useProfileStore()

  const utils = trpc.useUtils()

  const mastheadMutation = trpc.profile.setMastheadUniversalTokenId.useMutation(
    {
      onSuccess: async () => {
        setIsMutating(false)
        await new Promise((resolve) => setTimeout(resolve, 1000)) // wait for apollo cache to update
        utils.profile.invalidate() // invalidate and refetch profile
        if (onComplete) onComplete()
      },
    }
  )

  const handleClickMasthead = async () => {
    if (!isConnected || !account) {
      showDialog?.()
      return
    }

    setIsLoading(true)
    setIsMutating(true)

    const toastId = toast.loading("Pinning artwork...")

    try {
      // pin or unpin?
      const artworkToPin =
        mastheadArtwork?.universal_token_id === artwork.universal_token_id
          ? ""
          : artwork

      await mastheadMutation.mutateAsync({
        mastheadUniversalTokenId: artworkToPin
          ? artworkToPin.universal_token_id
          : "",
        userAddress: account?.ethereum_address,
      })
      toast.success(
        `Artwork has been ${
          artworkToPin ? "pinned" : "unpinned"
        } to your profile`,
        { id: toastId }
      )
      setMastheadArtwork(artworkToPin)
    } catch (error: any) {
      toast.error("Failed to pin artwork to your profile", { id: toastId })
    } finally {
      setIsLoading(false)
      setIsMutating(false)
    }
  }

  return {
    isLoading,
    isMutating,
    mastheadArtwork,
    setMastheadArtwork,
    handleClickMasthead,
  }
}
