"use client"

import {
  Erc721Token,
  Erc721Metadata,
  NftAuction,
  Erc721TokenSalePrice,
  Erc721MarketSold,
  User,
  NftAuctionSettledEvent,
  Erc721AcceptedBid,
  Maybe,
} from "@/graphql/generated/apollo/graphql"
import { useFavorites } from "@/web/hooks/useFavorites"
import { useMasthead } from "@/web/hooks/useMasthead"
import { AvatarProfile } from "@/ui/avatarProfile"
import { Button } from "@/ui/button"
import { DropdownMenu } from "@/ui/dropdown"
import { EthformatAddress } from "@/ui/ethaddress"
import { FavoriteToggler } from "@/ui/favoriteToggler"
import HR from "@/ui/hr"
import { cn } from "@/utils/cn"
import { getHighestOffer, getUsdCurrencyObject } from "@/utils/priceUtils"
import { heapTrack } from "@/utils/heap"
import Link from "next/link"
import useArtwork from "./useArtwork"
import { useFavoritesStore } from "@/web/stores/favorites"
import { useToggle } from "react-use"
import { currencyTypeByAddress, getCurrencyBySymbol } from "@/utils/currency"
import { useProfileStore } from "@/web/stores/profile"
import {
  actionTypes,
  useTransactionModalStore,
} from "@/web/stores/transactionModal"
import { supportsAuction } from "@/utils/getTokenAbi"
import { useAccount } from "wagmi"
import clsx from "clsx"
import { addressesAreEqual } from "@/utils/addressesAreEqual"
import Countdown from "../shared/Countdown"
import { Icon } from "@/ui/icon"
import { chainIdToSlug } from "@/utils/chains"
import { CHAIN_ID } from "@/constants/environment"
import { useEffect, useRef } from "react"
import { useCurrencyUsd } from "@/web/hooks/useCurrencyUsd"
import { formatEther } from "viem"
import { CountdownButton } from "@/ui/countdown-button"
import useCurrencyStore from "@/web/stores/currency"

export interface Erc721MetadataExtended extends Erc721Metadata {
  metadata: {
    [key: string]: any
  }
}

export interface Erc721TokenExtended extends Erc721Token {
  erc721_metadata?: Erc721MetadataExtended
}

export const ArtworkMedia = ({
  image,
  children,
  video,
  href,
  page,
  imageContainerClassName,
  straightTop,
}: {
  image?: string
  image_blurred?: string
  video?: string
  children?: React.ReactNode
  href?: string
  isActive?: boolean
  page?: string
  className?: string
  imageContainerClassName?: string
  straightTop?: boolean
}): JSX.Element => {
  const mediaRef = useRef(null)
  const ipfsImage = image?.startsWith("ipfs://")
  const imageUrl = ipfsImage
    ? `https://ipfs.io/ipfs/${image?.split("/").pop()}`
    : image
  const trackPage = () => {
    heapTrack(`${page} - artwork clicked`)
  }
  const Wrapper = ({ children }: { children: React.ReactNode }) => {
    if (href) {
      return (
        <Link href={href || ""} onClick={trackPage}>
          {children}
        </Link>
      )
    }
    return <div onClick={trackPage}>{children}</div>
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (imageUrl && entry.target instanceof HTMLImageElement) {
              entry.target.src = imageUrl
              return
            }
            if (video && entry.target instanceof HTMLVideoElement) {
              entry.target.src = video
              entry.target.load()
              return
            }
          }
          if (imageUrl && entry.target instanceof HTMLImageElement) {
            entry.target.src = ""
            return
          }
          if (video && entry.target instanceof HTMLVideoElement) {
            entry.target.src = ""
            return
          }
        })
      },
      {
        threshold: 0.01,
      }
    )

    if (mediaRef.current) {
      observer.observe(mediaRef.current)
    }

    return () => {
      if (mediaRef.current) {
        observer.unobserve(mediaRef.current)
      }
    }
  }, [imageUrl, video])
  return (
    <Wrapper>
      <div
        className={cn(
          `
            relative aspect-[359/430] group/artwork flex items-center overflow-hidden rounded-md
            border-sr-border-quaternary bg-sr-bg-tertiary p-4 duration-300
            hover:bg-sr-500/30 hover:dim:bg-sr-600/20
          `,
          straightTop && "!rounded-t-none",
          imageContainerClassName
        )}
      >
        {video ? (
          <video
            ref={mediaRef}
            className="h-full w-full"
            playsInline
            src={video}
            muted
            autoPlay
            loop
          />
        ) : (
          image && (
            <img
              ref={mediaRef}
              src={imageUrl ?? ""}
              alt=""
              className="max-h-[98%] w-full object-contain"
            />
          )
        )}
        {children}
      </div>
    </Wrapper>
  )
}

export const ArtworkDetails = ({
  artwork,
  hideAvatars = false,
  hideMobileContent = false,
  showLike = false,
  showPinning = false,
  favorites = false,
}: {
  artwork: Erc721TokenExtended
  hideAvatars?: boolean
  hideMobileContent?: boolean
  showLike?: boolean
  showPinning?: boolean
  favorites?: boolean
  handleRemoveArtwork?: () => void
}): JSX.Element => {
  const { profile } = useProfileStore()
  const { chain, address } = useAccount()
  const { mastheadArtwork, handleClickMasthead } = useMasthead(artwork)
  const { owner } = useArtwork(artwork)
  const { isWaitingRemoval } = useFavoritesStore()
  const { ethPrice, rarePrice } = useCurrencyStore()
  const ownsArtwork = addressesAreEqual(
    profile?.ethereum_address,
    owner?.ethereum_address
  )
  const createdArtwork =
    artwork.erc721_creator?.address &&
    profile?.ethereum_address &&
    artwork.erc721_creator?.address === profile?.ethereum_address

  const sameOwnerAsCreator =
    artwork.erc721_creator?.address &&
    owner?.ethereum_address &&
    artwork.erc721_creator?.address === owner?.ethereum_address

  const [, toggleOpen] = useToggle(false)
  const { handleClickFavorite, isMutating } = useFavorites(artwork, () => {
    toggleOpen()
  })
  const { setAction, setIsOpen, setToken } = useTransactionModalStore()

  const openModal = (action: actionTypes): void => {
    setAction(action)
    setToken(artwork)
    setIsOpen(true)
  }
  const isAuctionSupported = supportsAuction(
    artwork.contract_address,
    chain?.id || 1
  )
  const isUnsettledAuction =
    artwork?.nft_auctions?.[0]?.auction_state === "UNSETTLED_AUCTION"
  const canSettleAuction =
    artwork?.nft_auctions?.[0]?.current_nft_auction_bid?.[0]?.bidder
      ?.ethereum_address === profile?.ethereum_address

  const highestOffer = getHighestOffer({
    offers: artwork.current_offer ?? [],
    rarePrice,
    ethPrice,
  })
  return (
    <div
      className={cn(
        "flex flex-col space-y-3 border-b border-sr-border-quaternary py-4",
        hideMobileContent &&
          `
            hidden
            lg:flex
          `
      )}
    >
      <div className="flex items-center justify-between">
        <Link
          className={`
            truncate text-lg
            hover:underline
          `}
          href={`/artwork/${chainIdToSlug(CHAIN_ID) || "eth"}/${artwork.contract_address}/${artwork.token_id}`}
        >
          {artwork.erc721_metadata?.metadata?.name}
        </Link>
        <p>
          {showLike && <FavoriteToggler artwork={artwork} />}
          {/* {favorites && (
            <>
              <DropdownMenu
                align="end"
                rotateIcon
                open={open}
                onOpenChange={toggleOpen}
              >
                <DropdownMenu.Button
                  className="flex gap-x-3 w-32"
                  onClick={() =>
                    handleClickFavorite(
                      isWaitingRemoval.includes(artwork.token_id)
                        ? "add"
                        : "remove"
                    )
                  }
                >
                  {isMutating ? (
                    <span className="opacity-50 cursor-wait">
                      {isWaitingRemoval.includes(artwork.token_id)
                        ? "Adding..."
                        : "Removing..."}
                    </span>
                  ) : isWaitingRemoval.includes(artwork.token_id) ? (
                    <span>Add to favourites</span>
                  ) : (
                    <span>Unfavourite</span>
                  )}
                </DropdownMenu.Button>
              </DropdownMenu>
            </>
          )} */}
          {showPinning && (
            <>
              <DropdownMenu
                align="end"
                side="bottom"
                contentClassName="w-auto !p-4 pr-4"
                rotateIcon
                small
              >
                <div className="flex flex-col gap-y-4 text-left">
                  {(ownsArtwork || createdArtwork) && (
                    <button
                      className="hover:underline"
                      onClick={handleClickMasthead}
                    >
                      {mastheadArtwork?.universal_token_id ===
                      artwork.universal_token_id
                        ? "Unpin from top of profile"
                        : "Pin to top of profile"}
                    </button>
                  )}
                  {ownsArtwork && isAuctionSupported ? (
                    artwork?.nft_auctions?.[0]?.auction_state ===
                      "RUNNING_AUCTION" ||
                    artwork?.nft_auctions?.[0]?.auction_state ===
                      "PENDING_AUCTION" ? (
                      <button
                        className={`
                          text-left
                          hover:underline
                        `}
                        onClick={() => openModal("cancelAuction")}
                      >
                        Cancel auction
                      </button>
                    ) : isUnsettledAuction ? (
                      <button
                        className={`
                          text-left
                          hover:underline
                        `}
                        onClick={() => openModal("settleAuction")}
                      >
                        Settle Auction
                      </button>
                    ) : (
                      <button
                        className={`
                          text-left
                          hover:underline
                        `}
                        onClick={() => openModal("auction")}
                      >
                        Start an auction
                      </button>
                    )
                  ) : null}
                  {ownsArtwork && !isUnsettledAuction ? (
                    <>
                      <button
                        className={`
                          text-left
                          hover:underline
                        `}
                        onClick={() => openModal("price")}
                      >
                        {artwork?.current_sale_price?.[0]?.amount
                          ? "Update"
                          : "Set a"}{" "}
                        list price
                      </button>
                      {artwork?.current_sale_price?.[0]?.amount && (
                        <button
                          className={`
                            text-left
                            hover:underline
                          `}
                          onClick={() => openModal("removePrice")}
                        >
                          Remove list price
                        </button>
                      )}

                      {!isUnsettledAuction && highestOffer?.amount && (
                        <button
                          className={`
                            text-left
                            hover:underline
                          `}
                          onClick={() => openModal("acceptOffer")}
                        >
                          Accept offer
                        </button>
                      )}
                      <HR />
                      <button
                        className={`
                          text-left
                          hover:underline
                        `}
                        onClick={() => openModal("transfer")}
                      >
                        Transfer
                      </button>
                    </>
                  ) : (
                    <>
                      {profile?.ethereum_address &&
                      (highestOffer?.bidder_address ===
                        profile?.ethereum_address ||
                        highestOffer?.bidder?.ethereum_address ==
                          profile?.ethereum_address) ? (
                        <button
                          className={`
                            text-left
                            hover:underline
                          `}
                          onClick={() => openModal("removeOffer")}
                        >
                          Remove offer
                        </button>
                      ) : (
                        <button
                          className={`
                            text-left
                            hover:underline
                          `}
                          onClick={() => openModal("offer")}
                        >
                          Make an offer
                        </button>
                      )}
                      {address && isUnsettledAuction && canSettleAuction ? (
                        <button
                          className={`
                            text-left
                            hover:underline
                          `}
                          onClick={() => openModal("settleAuction")}
                        >
                          Settle Auction
                        </button>
                      ) : null}
                      {!isUnsettledAuction &&
                        !!artwork?.current_sale_price?.[0]?.amount && (
                          <button
                            className={`
                              text-left
                              hover:underline
                            `}
                            onClick={() => openModal("buy")}
                          >
                            Buy now
                          </button>
                        )}
                      {artwork?.nft_auctions?.[0]?.auction_state ===
                        "RUNNING_AUCTION" ||
                        (artwork?.nft_auctions?.[0]?.auction_state ===
                          "PENDING_AUCTION" && (
                          <button
                            className={`
                              text-left
                              hover:underline
                            `}
                            onClick={() => openModal("bid")}
                          >
                            Place a bid
                          </button>
                        ))}
                    </>
                  )}
                  {favorites && (
                    <DropdownMenu.Button
                      className="flex w-32 gap-x-3"
                      onClick={() =>
                        handleClickFavorite(
                          isWaitingRemoval.includes(artwork.token_id)
                            ? "add"
                            : "remove"
                        )
                      }
                    >
                      {isMutating ? (
                        <span className="cursor-wait opacity-50">
                          {isWaitingRemoval.includes(artwork.token_id)
                            ? "Adding..."
                            : "Removing..."}
                        </span>
                      ) : isWaitingRemoval.includes(artwork.token_id) ? (
                        <span>Add to favourites</span>
                      ) : (
                        <span>Unfavourite</span>
                      )}
                    </DropdownMenu.Button>
                  )}
                </div>
              </DropdownMenu>
            </>
          )}
        </p>
      </div>
      {!hideAvatars && (
        <div className="grid grid-cols-2">
          {artwork.erc721_creator?.creator && (
            <AvatarProfile size="sm" user={artwork.erc721_creator.creator} />
          )}
          {!sameOwnerAsCreator && (
            <AvatarProfile
              size="sm"
              user={owner}
              address={artwork.erc721_owner?.owner_address}
            />
          )}
        </div>
      )}
    </div>
  )
}

export const ArtworkListPriceButton = ({
  buttonTitle,
  priceTitle,
  price,
  currency = "ETH",
  historicalUsdPrice,
  fallback,
  onClick,
}: {
  buttonTitle: string
  priceTitle: string
  price?:
    | Erc721TokenSalePrice
    | Erc721MarketSold
    | NftAuctionSettledEvent
    | Erc721AcceptedBid
    | { amount: number }
  fallback?: string
  currency?: string
  historicalUsdPrice?: number
  onClick?: () => void
}): JSX.Element => {
  const fullCurrency = getCurrencyBySymbol(currency || "ETH")
  const { price: fullPrice } = useCurrencyUsd({
    amount: BigInt(price?.amount || 0),
    historicalUsd: historicalUsdPrice,
    currency: fullCurrency,
  })
  return (
    <div className="flex items-center justify-start text-sm">
      <Button onClick={onClick} kind="secondary" size="sm" uppercase>
        {buttonTitle}
      </Button>
      <span className="ml-2 mr-[3px] text-sr-text-tertiary">{priceTitle}</span>
      {price?.amount ? (
        <div
          className={`
            text-sm uppercase tracking-wide text-black
            dark:text-white
          `}
        >
          {fullPrice}
        </div>
      ) : (
        <>{fallback}</>
      )}
    </div>
  )
}

export const ArtworkListPrice = ({
  title,
  price,
  currency = "ETH",
  historicalUsdPrice,
  fallback,
  textClassName,
}: {
  title: string
  price?:
    | Erc721TokenSalePrice
    | Erc721MarketSold
    | NftAuctionSettledEvent
    | Erc721AcceptedBid
    | { amount: number }
  fallback?: string
  currency?: string
  historicalUsdPrice?: number
  textClassName?: string
}): JSX.Element => {
  const fullCurrency = getCurrencyBySymbol(currency || "ETH")
  const { price: fullPrice } = useCurrencyUsd({
    amount: BigInt(price?.amount || 0),
    historicalUsd: historicalUsdPrice,
    currency: fullCurrency,
  })
  return (
    <div>
      <div className="text-sm tracking-wide text-typography-grey-2">
        {title}
      </div>
      {price?.amount ? (
        <div
          className={cn(
            `
              text-sm uppercase tracking-wide text-black
              dark:text-white
            `,
            textClassName
          )}
        >
          {fullPrice}
        </div>
      ) : (
        <>{fallback}</>
      )}
    </div>
  )
}

export const CurrentOffer = ({
  amount,
  currency = "ETH",
  bidder,
}: {
  amount: number
  currency?: string
  bidder?: User
}): JSX.Element => {
  const { currency: formattedPrice } = getUsdCurrencyObject({
    amount: BigInt(amount),
    currencyType: currency,
  })

  return (
    <div>
      {bidder?.username ? (
        <Link
          className={bidder?.username ? "" : "pointer-events-none"}
          href={`/${bidder?.username}`}
          onClick={() => heapTrack(`User ${bidder?.username} profile clicked`)}
        >
          <div className="flex items-center gap-x-1 truncate text-sm tracking-wide text-typography-grey-2">
            <span className="whitespace-nowrap">Highest offer by</span>
            <span className="text-sr-text-primary">
              @<AvatarProfile size="sm" user={bidder} hideAvatar />
            </span>
          </div>
        </Link>
      ) : (
        <div className="flex items-center gap-x-1 truncate text-sm tracking-wide text-typography-grey-2">
          <span className="whitespace-nowrap">Highest offer by</span>
          <span className="text-sr-text-primary">
            {EthformatAddress(bidder?.ethereum_address as string)}
          </span>
        </div>
      )}

      <div
        className={`
          text-sm uppercase tracking-wide text-black
          dark:text-white
        `}
      >
        {formattedPrice}
      </div>
    </div>
  )
}

export const ArtworkShowcaseSales = ({
  artwork,
}: {
  artwork: Erc721TokenExtended
}): JSX.Element => {
  const reserveAuction = artwork.nft_auctions?.find((v) => {
    if (v && v.auction_state === "PENDING_AUCTION" && v.reserve_price > 0) {
      return true
    }
    return false
  })

  const { setAction, setIsOpen, setToken } = useTransactionModalStore()
  const showModal = (action: actionTypes): void => {
    setIsOpen(true)
    setToken(artwork)
    setAction(action)
  }
  return (
    <div className={cn("my-3 grid grid-cols-1")}>
      {reserveAuction ? (
        <ArtworkListPriceButton
          buttonTitle="Place a bid"
          priceTitle="Reserve"
          price={{ amount: reserveAuction.reserve_price } ?? undefined}
          fallback="-"
          currency={currencyTypeByAddress(reserveAuction?.currency_address)}
          onClick={() => showModal("bid")}
        />
      ) : artwork.current_sale_price?.[0] ? (
        <ArtworkListPriceButton
          buttonTitle="Buy"
          priceTitle="List price"
          price={artwork.current_sale_price?.[0] ?? undefined}
          fallback="-"
          currency={artwork.current_sale_price?.[0]?.currency_address?.type}
          onClick={() => showModal("buy")}
        />
      ) : (
        <ArtworkListPriceButton
          buttonTitle="Make an offer"
          priceTitle=""
          price={artwork.current_sale_price?.[0] ?? undefined}
          fallback=""
          currency={artwork.current_sale_price?.[0]?.currency_address?.type}
          onClick={() => showModal("offer")}
        />
      )}
    </div>
  )
}

export const LastSalePrice = ({
  artwork,
  hideMobileContent = false,
  textClassName,
}: {
  artwork: Erc721TokenExtended
  hideMobileContent?: boolean
  textClassName?: string
}): JSX.Element => {
  const eventType = artwork.most_recent_sale?.nft_event_type

  return (
    <div
      className={cn(
        "my-3 grid grid-cols-1",
        hideMobileContent &&
          `
            hidden
            lg:grid
          `
      )}
    >
      {artwork.most_recent_sale && (
        <>
          {eventType === "AUCTION_SETTLED" &&
            artwork.most_recent_sale.auction_settled_event?.bidder &&
            artwork.most_recent_sale.auction_settled_event?.amount && (
              <ArtworkListPrice
                title="Last sale price"
                textClassName={textClassName}
                price={
                  artwork.most_recent_sale.auction_settled_event ?? undefined
                }
                currency={
                  artwork.most_recent_sale.auction_settled_event?.currency?.type
                }
                historicalUsdPrice={
                  artwork.most_recent_sale.usd_value || undefined
                }
              />
            )}
          {eventType === "ACCEPT_BID" && (
            <ArtworkListPrice
              title="Last sale price"
              textClassName={textClassName}
              price={artwork.most_recent_sale.erc721_accepted_bid ?? undefined}
              currency={
                artwork.most_recent_sale.erc721_accepted_bid?.currency?.type
              }
              historicalUsdPrice={
                artwork.most_recent_sale.usd_value || undefined
              }
            />
          )}
          {eventType === "SALE" && (
            <>
              <ArtworkListPrice
                title="Last sale price"
                textClassName={textClassName}
                price={artwork.most_recent_sale.erc721_market_sold ?? undefined}
                currency={
                  artwork.most_recent_sale.erc721_market_sold?.currency?.type
                }
                historicalUsdPrice={
                  artwork.most_recent_sale.usd_value || undefined
                }
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export const ArtworkSales = ({
  artwork,
  reserveAuction,
  hideMobileContent = false,
}: {
  artwork: Erc721TokenExtended
  hideMobileContent?: boolean
  reserveAuction?: Maybe<NftAuction>
}): JSX.Element => {
  const { ethPrice, rarePrice } = useCurrencyStore()
  const currentOffer = getHighestOffer({
    offers: artwork.current_offer ?? [],
    rarePrice,
    ethPrice,
  })
  const showOffer = currentOffer?.amount
    ? Number(formatEther(BigInt(currentOffer?.amount))) >= 0.01
    : false
  const eventType = artwork.most_recent_sale?.nft_event_type

  return (
    <div
      className={cn(
        "my-3 grid grid-cols-2",
        hideMobileContent &&
          `
            hidden
            lg:grid
          `
      )}
    >
      {reserveAuction ? (
        <ArtworkListPrice
          title="Reserve"
          price={{ amount: reserveAuction.reserve_price } ?? undefined}
          fallback="-"
          currency={currencyTypeByAddress(reserveAuction?.currency_address)}
        />
      ) : (
        <ArtworkListPrice
          title="List price"
          price={artwork.current_sale_price?.[0] ?? undefined}
          fallback="-"
          currency={artwork.current_sale_price?.[0]?.currency_address?.type}
        />
      )}
      {currentOffer && showOffer && (
        <CurrentOffer
          amount={currentOffer.amount}
          currency={currentOffer.currency?.type}
          bidder={
            (currentOffer.bidder as User) ||
            ({
              ethereum_address: currentOffer.bidder_address,
            } as User)
          }
        />
      )}
      {artwork.most_recent_sale && !currentOffer && !showOffer && (
        <>
          {eventType === "AUCTION_SETTLED" &&
            artwork.most_recent_sale.auction_settled_event?.bidder &&
            artwork.most_recent_sale.auction_settled_event?.amount && (
              <ArtworkListPrice
                title="Last sale price"
                price={
                  artwork.most_recent_sale.auction_settled_event ?? undefined
                }
                currency={
                  artwork.most_recent_sale.auction_settled_event?.currency?.type
                }
                historicalUsdPrice={
                  artwork.most_recent_sale.usd_value || undefined
                }
              />
            )}
          {eventType === "ACCEPT_BID" && (
            <ArtworkListPrice
              title="Last sale price"
              price={artwork.most_recent_sale.erc721_accepted_bid ?? undefined}
              currency={
                artwork.most_recent_sale.erc721_accepted_bid?.currency?.type
              }
              historicalUsdPrice={
                artwork.most_recent_sale.usd_value || undefined
              }
            />
          )}
          {eventType === "SALE" && (
            <>
              <ArtworkListPrice
                title="Last sale price"
                price={artwork.most_recent_sale.erc721_market_sold ?? undefined}
                currency={
                  artwork.most_recent_sale.erc721_market_sold?.currency?.type
                }
                historicalUsdPrice={
                  artwork.most_recent_sale.usd_value || undefined
                }
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export const HighestBid = ({
  amount,
  bidder,
  bidder_address,
  currency = "ETH",
  className,
}: {
  amount: number
  bidder: Partial<User> | undefined
  bidder_address: string
  currency?: string
  className?: string
}): JSX.Element => {
  const fullCurrency = getCurrencyBySymbol(currency || "ETH")
  const { price } = useCurrencyUsd({
    amount: BigInt(amount),
    currency: fullCurrency,
  })
  return (
    <div
      className={clsx(
        "mt-2 overflow-hidden text-ellipsis whitespace-nowrap text-sm text-sr-text-tertiary",
        className
      )}
    >
      Highest bid <span className="text-sr-text-primary">{price}</span> by{" "}
      {bidder?.username ? (
        <span className="text-sr-text-primary">
          @<AvatarProfile size="sm" user={bidder} hideAvatar />
        </span>
      ) : (
        <span className="text-sr-text-primary">
          {EthformatAddress(bidder_address)}
        </span>
      )}
    </div>
  )
}

export const ArtworkAuction = ({
  artwork,
  auction,
  kind = "default",
}: {
  artwork: Erc721TokenExtended
  auction: NftAuction
  kind?: "showcase" | "default" | "basic"
}): JSX.Element => {
  const { setIsOpen, setAction, setToken } = useTransactionModalStore()
  const currentBid =
    auction && auction.current_nft_auction_bid
      ? auction.current_nft_auction_bid[0]
      : undefined
  const showModal = (action: actionTypes) => {
    setIsOpen(true)
    setToken(artwork)
    setAction(action)
  }
  return (
    <div
      className={cn(
        "my-3 flex flex-col",
        kind === "showcase" && "flex-row gap-3"
      )}
    >
      <CountdownButton
        className="min-w-40 whitespace-nowrap"
        countdown={auction.ending_time ?? undefined}
        onClick={() => showModal("bid")}
      >
        Countdown
      </CountdownButton>
      {currentBid &&
      (currentBid.bidder?.username || currentBid.bidder_address) ? (
        <HighestBid
          amount={currentBid.amount}
          bidder_address={currentBid.bidder_address}
          bidder={currentBid.bidder || undefined}
          currency={currentBid.currency?.type}
        />
      ) : (
        <p className="mt-2 overflow-hidden text-ellipsis whitespace-nowrap text-sm text-sr-text-tertiary">
          Place a bid
        </p>
      )}
    </div>
  )
}

export const ArtworkUpcomingAuction = ({
  artwork,
  auction,
}: {
  artwork: Erc721TokenExtended
  auction: NftAuction
  kind?: "showcase" | "default" | "basic"
}): JSX.Element => {
  const fullCurrency = getCurrencyBySymbol(auction.currency_address)
  const { price } = useCurrencyUsd({
    amount: BigInt(auction.reserve_price || 0),
    currency: fullCurrency,
  })
  return (
    <div>
      {auction.starting_time &&
      Date.parse(auction.starting_time) > Date.now() ? (
        <div className="pt-4">
          <div
            className={`
              flex items-center justify-center rounded-md border border-sr-border-secondary py-1 text-sm
              text-sr-text-secondary
            `}
          >
            <Icon name="clock" className="mr-2" />
            <Countdown timestamp={Date.parse(auction.starting_time)} />
          </div>
          <div className="mt-2 flex gap-1 text-sm tracking-wide text-sr-text-tertiary">
            Reserve price
            <span className="text-sr-text-primary">{price}</span>
          </div>
        </div>
      ) : (
        <>
          <ArtworkSales artwork={artwork} reserveAuction={auction} />
        </>
      )}
    </div>
  )
}
