import React, { forwardRef, useState, ComponentProps } from "react"
import { cn } from "@/utils/cn"
import Countdown from "react-countdown"
import { Button, ButtonKind } from "@/ui/button"

const buttonKindCountdownColors = {
  primary: `bg-white dark:bg-black`,
  secondary: `bg-black dark:bg-white`,
  tertiarty: `bg-black dark:bg-white`,
  ghost: `bg-black dark:bg-white`,
  invert: `bg-black dark:bg-white`,
}

interface CountdownButtonProps extends ComponentProps<typeof Button> {
  kind?: ButtonKind
  countdown: string | number | Date | undefined
  onCountdownComplete?: () => void
  showChildOnHover?: boolean
}

export const CountdownButton = forwardRef<
  HTMLButtonElement,
  CountdownButtonProps
>(
  (
    {
      children,
      kind = "primary",
      countdown,
      showChildOnHover,
      onCountdownComplete,
      ...props
    },
    ref
  ): JSX.Element => {
    const buttonCountdown = buttonKindCountdownColors[kind]
    const [isHovered, setIsHovered] = useState(false)
    return (
      <Button
        ref={ref}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...props}
      >
        {!showChildOnHover && (
          <div className="flex items-center justify-center gap-x-3">
            <span className="relative flex size-3 items-center justify-center">
              <span
                className={cn(
                  "absolute inline-flex h-full w-full animate-ping rounded-full opacity-60",
                  buttonCountdown
                )}
              ></span>
              <span
                className={cn(
                  "relative inline-flex size-2 rounded-full",
                  buttonCountdown
                )}
              ></span>
            </span>
            <Countdown
              date={countdown}
              renderer={({ days, hours, minutes, seconds }) => {
                return (
                  <span>
                    {days > 0 && `${days}d`}{" "}
                    {(hours > 0 || days > 0) && `${hours}h`}{" "}
                    {minutes > 0 && `${minutes}m`}{" "}
                    {seconds > 0 && days === 0 && `${seconds}s`}
                  </span>
                )
              }}
              onComplete={onCountdownComplete}
            />
          </div>
        )}
        {showChildOnHover && !isHovered ? (
          <div className="flex items-center justify-center gap-x-3">
            <span className="relative flex size-3 items-center justify-center">
              <span
                className={cn(
                  "absolute inline-flex h-full w-full animate-ping rounded-full opacity-60",
                  buttonCountdown
                )}
              ></span>
              <span
                className={cn(
                  "relative inline-flex size-2 rounded-full",
                  buttonCountdown
                )}
              ></span>
            </span>
            <Countdown
              date={countdown}
              renderer={({ days, hours, minutes, seconds }) => {
                return (
                  <span>
                    {days > 0 && `${days}d`} {hours > 0 && `${hours}h`}{" "}
                    {minutes > 0 && `${minutes}m`}{" "}
                    {seconds > 0 && `${seconds}s`}
                  </span>
                )
              }}
              onComplete={onCountdownComplete}
            />
          </div>
        ) : showChildOnHover && isHovered ? (
          children
        ) : null}
      </Button>
    )
  }
)
